import { template as template_7e635177e4ac4d138de80cb425dd3fcc } from "@ember/template-compiler";
const FKControlMenuContainer = template_7e635177e4ac4d138de80cb425dd3fcc(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
