import { template as template_adb9aa3ec8f445b087614dc648782d6c } from "@ember/template-compiler";
const FKLabel = template_adb9aa3ec8f445b087614dc648782d6c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
